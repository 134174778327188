








































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CatalogService from "@/api/catalogs";

@Component({})
export default class AddTrailerModal extends Vue {
  placas = "";
  storage = 0;
  ecoNum = "";
  @Emit()
  close() {
    return true;
  }

  @Prop(Number) storageId: any;
  @Prop(Object) trailer: any;

  addTrailer() {
    if (
      Object.keys(this.trailer).length === 0 &&
      this.trailer.constructor === Object
    ) {
      CatalogService.addTrailer({
        plate: this.placas,
        storage_id: this.storageId,
        eco_num: this.ecoNum
      }).then(
        res => {
          this.$store.dispatch("TransportModule/addTrailer", res.data);
          this.$buefy.notification.open({
            message: "Se ha agregado una caja",
            type: "is-success"
          });
          this.$emit("close", true);
        },
        () => {
          this.$buefy.notification.open({
            message: "Este número de caja o placas ya existen",
            type: "is-danger"
          });
        }
      );
    } else {
      CatalogService.editTrailer(this.trailer.id, {
        plate: this.placas,
        eco_num: this.ecoNum
      }).then(
        res => {
          this.$store.dispatch("TransportModule/replaceTrailer", res.data);
          this.$buefy.notification.open({
            message: "Se ha modificado una caja",
            type: "is-success"
          });
          this.$emit("close", true);
        },
        () => {
          this.$buefy.notification.open({
            message: "Esta caja no se pudo editar",
            type: "is-warning"
          });
        }
      );
    }
  }
  mounted() {
    this.storage = this.storageId;
    if (
      Object.keys(this.trailer).length === 0 &&
      this.trailer.constructor === Object
    ) {
      return;
    }
    this.placas = this.trailer.plate;
    this.ecoNum = this.trailer.eco_num;
  }
}
