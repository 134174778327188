



















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CatalogService from "@/api/catalogs";

@Component({})
export default class AddCamionModal extends Vue {
  placas = "";
  chofer = "";
  lineaId = 1;
  storage = 0;
  ecoNum = "";
  @Emit()
  close() {
    return true;
  }
  @Prop(Array) companies: any;
  @Prop(Number) storageId: any;
  @Prop(Object) truck: any;

  addCamion() {
    if (
      Object.keys(this.truck).length === 0 &&
      this.truck.constructor === Object
    ) {
      CatalogService.addTruck({
        plates: this.placas,
        driver_full_name: this.chofer,
        transport_company_id: this.lineaId,
        storage_id: this.storage,
        eco_num: this.ecoNum
      }).then(res => {
        this.$store.dispatch("TransportModule/addTruck", res.data);
        this.$emit("close", true);
      });
    } else {
      CatalogService.editTruck(this.truck.id, {
        plates: this.placas,
        driver_full_name: this.chofer,
        transport_company_id: this.lineaId,
        storage_id: this.storage,
        eco_num: this.ecoNum
      }).then(res => {
        this.$store.dispatch("TransportModule/replaceTruck", res.data);
        this.$emit("close", true);
      });
    }
  }
  mounted() {
    this.storage = this.storageId;
    if (
      Object.keys(this.truck).length === 0 &&
      this.truck.constructor === Object
    ) {
      return;
    }
    this.placas = this.truck.plates;
    this.chofer = this.truck.driver_full_name;
    this.lineaId = this.truck.transport_company_id;
    this.storage = this.truck.storage_id;
  }
}
