





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import AddCamionModal from "@/components/catalogs/AddCamionModal.vue";
import AddTrailerModal from "@/components/catalogs/AddTrailerModal.vue";
import CatalogService from "@/api/catalogs";
import { mapGetters } from "vuex";

@Component({
  components: {
    AddCamionModal,
    AddTrailerModal
  },
  computed: {
    ...mapGetters("TransportModule", {
      companies: "getCompanies"
    }),
    ...mapGetters("TransportModule", {
      cajas: "getTrailers"
    }),
    ...mapGetters("TransportModule", {
      trucks: "getTrucks"
    }),
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  }
})
export default class CamionesCatalog extends Vue {
  isAddingCamion = false;
  isAddingTrailer = false;
  companies!: any[];
  cajas!: any[];
  storage!: any;
  trucks!: any[];
  selectedTruck: any = {};
  selectedTrailer: any = {};
  updateKey = 0;
  isOpen = 0;
  options = ["lineas", "camiones", "cajas"];

  columnsCamiones = [
    {
      field: "id",
      label: "ID",
      width: "100",
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: "eco_num",
      label: "No Eco.",
      sortable: true,
      searchable: true
    },
    {
      field: "company",
      label: "Linea",
      sortable: true,
      searchable: true
    },
    {
      field: "plates",
      label: "Placas",
      sortable: true,
      searchable: true
    },
    {
      field: "driver_full_name",
      label: "Chofer",
      sortable: true,
      searchable: true
    }
  ];
  columnsCajas = [
    {
      field: "eco_num",
      label: "No Eco.",
      sortable: true,
      searchable: true
    },
    {
      field: "plate",
      label: "Placas",
      searchable: true
    }
  ];

  columnsLineas = [
    {
      field: "name",
      label: "Nombre de la línea",
      sortable: true
    }
  ];

  @Watch("selectedTruck")
  toggleEditTruck(val: any) {
    if (Object.keys(val).length === 0 && val.constructor === Object) {
      return;
    }
    this.isAddingCamion = true;
  }

  @Watch("selectedTrailer")
  toggleEditTrailer(val: any) {
    if (Object.keys(val).length === 0 && val.constructor === Object) {
      return;
    }
    this.isAddingTrailer = true;
  }

  toggleAddTruck() {
    this.selectedTruck = {};
    this.isAddingCamion = true;
    this.isOpen = 1;
    event?.stopPropagation();
  }
  toggleAddTrailer() {
    this.selectedTrailer = {};
    this.isAddingTrailer = true;
    this.isOpen = 2;
    event?.stopPropagation();
  }

  closeTruck(val: boolean) {
    this.isAddingCamion = !val;
    this.selectedTruck = {};
    this.updateKey += 1;
    event?.stopPropagation();
  }

  closeTrailer(val: boolean) {
    this.isAddingTrailer = !val;
    this.selectedTrailer = {};
    this.updateKey += 1;
    event?.stopPropagation();
  }
  addLinea() {
    this.isOpen = 0;
    event?.stopPropagation();
    this.$buefy.dialog.prompt({
      message: `¿Cual es el nombre de la línea?`,
      inputAttrs: {
        placeholder: "Ingresa la línea",
        value: ""
      },
      trapFocus: true,
      onConfirm: value => {
        CatalogService.addLinea({ name: value }).then(res => {
          this.$store.dispatch("TransportModule/addCompany", res.data);
          this.$buefy.notification.open({
            message: "Se ha agregado una linea!",
            type: "is-success"
          });
        });
      }
    });
  }

  mounted() {
    CatalogService.getLineas().then(res => {
      this.$store.dispatch("TransportModule/setCompanies", res.data);
    });
    CatalogService.getTrailers().then(res => {
      this.$store.dispatch("TransportModule/setTrailers", res.data);
    });
    CatalogService.getTrucks(this.storage.id).then(res => {
      this.$store.dispatch("TransportModule/setTrucks", res.data);
    });
  }
}
