import http from "@/api/http";
class CatalogService {
  getStackTypes() {
    return http.get("/stack_type");
  }
  addTrailer(caja: any) {
    return http.post("/trailer", caja);
  }
  getTrailers() {
    return http.get("/trailer");
  }
  editTrailer(trailerId: number, trailer: any) {
    return http.patch(`/trailer/${trailerId}`, trailer);
  }
  addLinea(linea: any) {
    return http.post("/transport_company", linea);
  }
  getLineas() {
    return http.get("/transport_company");
  }
  addTruck(truck: any) {
    return http.post("/truck", truck);
  }
  getTrucks(storageId: number) {
    return http.get(`/truck/storage/${storageId}`);
  }
  editTruck(truckId: number, truck: any) {
    return http.patch(`/truck/${truckId}`, truck);
  }
}

export default new CatalogService();
